<template>
	<main>
        <HeaderTab :title="$t('invoice.devis.edit')"/>

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                    	<div v-if="loading" class="box">
                    		<LoadingSpinner class="col-12" />
                    	</div>
                    	<template v-else>
					        <div class="box">
					        	<div class="row">
					        		<div class="col-auto">
					        			{{ $t('invoice.stat_total_ht') }} : {{ quotation.ht_format }} - {{ $t('invoice.stat_total_ttc') }} : {{ quotation.ttc_format }} - {{ $t('invoice.stat_total_tva') }} : {{ quotation.vat_format }}
					        		</div>
									<div class="col-auto ml-auto d-flex">
								        <a href="" class="btn ml-3 btn-secondary" @click.prevent="quickPreview">
								            <i><font-awesome-icon :icon="['fas', 'eye']" /></i> {{ $t('global.apercu') }}
								        </a>
								        <a href="" class="btn ml-3 btn-secondary" @click.prevent="downloadPdf">
								            <i><font-awesome-icon v-if="processing_download" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'file-pdf']" /></i> {{ $t('action.imprimer') }}
								        </a>
								        <a href="" class="btn ml-3 btn-secondary" @click.prevent="convertQuotation">
								            <i><font-awesome-icon v-if="processing_convert" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'check']" /></i> {{ $t('action.convertir') }}
								        </a>
								        <a href="" class="btn ml-3 btn-secondary" @click.prevent="openModalSendQuotation">
								            <i><font-awesome-icon :icon="['far', 'paper-plane']" /></i> {{ $t('action.envoyer') }}
								        </a>
								        <a href="" class="btn ml-3 btn-secondary" @click.prevent="generateSignatureLink">
								            <i><font-awesome-icon v-if="processing_signature" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'signature']" /></i> {{ $t('action.signature') }}
								        </a>
								        <a href="" class="btn ml-3 btn-secondary" @click.prevent="openDocument">
								            <i><font-awesome-icon :icon="['fal', 'photo-video']" /></i> {{ $t('invoice.devis.pieces_jointes') }}
											<span v-if="quotation && quotation.nb_documents && quotation.nb_documents > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
												{{ quotation.nb_documents }}
											</span>
								        </a>
								        <a href="" class="btn ml-3 btn-secondary" @click.prevent="generateLink">
								            <i><font-awesome-icon v-if="processing_share" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'link']" /></i> {{ $t('invoice.devis.generate_link') }}
								        </a>
									</div>
					        	</div>
					        	<div class="row">
									<div class="col-6">
										<div class="mb-2">
											<label for="quotation_num" class="col-form-label">{{ $t('invoice.devis.numero') }}</label>
											<input type="text" class="form-control" readonly v-model="quotation.quotation_num">
										</div>
					        		</div>
									<div class="col-6">
										<div class="mb-2">
											<label for="quotation_status" class="col-form-label">{{ $t('invoice.devis.status_devis') }}</label>
											<e-select
												v-model="quotation.quotation_status"
												id="quotation_status"
												:options="quotation_status"
												:searchable="true"
												:allow-empty="false"
												:show-labels="false"
											>
												<template slot="option" slot-scope="{ option }">{{ $t('invoice.devis.status.'+option) }}</template>
												<template slot="singleLabel" slot-scope="{ option }">{{ $t('invoice.devis.status.'+option) }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
										</div>
					        		</div>
									<div class="col-6">
										<div class="mb-2">
											<label class="mb-1" for="tiers_id">{{ $t('tiers.billing_entity') }} *</label>
											<e-select
												v-model="quotation.author"
												id="entity_id"
												track-by="tiers_id"
												label="tiers_rs"
												:placeholder="getTrad('tiers.rechercher_billing_entity')"
												:selectedLabel="getTrad('global.selected_label')"
												:options="entity"
												:searchable="true"
												:allow-empty="false"
												:show-labels="false"
												:class="{ 'is-invalid': errors && errors.indexOf('author') > -1 }"
											>
												<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
										</div>
									</div>
									<div class="col-6">
										<div class="mb-2">
											<label class="mb-1" for="created_date">{{ $t('invoice.devis.created_date') }}</label>
											<e-datepicker v-model="quotation.quotation_created" id="created_date" ></e-datepicker>
										</div>
									</div>

									<div class="col-6">
										<div class="mb-2">
											<label class="mb-1 mr-2" for="tiers_id">{{ $t('tiers.tiers') }} *</label>
											<router-link v-if="quotation.tiers" :to="{ name: 'tiersFiche', params: { tiers_id: quotation.tiers.tiers_id }}">
												<i><font-awesome-icon :icon="['fal', 'link']"/></i>
                                            </router-link>
                                            <a v-if="quotation.tiers && quotation.tiers.tiers_hubspotid" :href="'https://app.hubspot.com/contacts/8547080/company/' + quotation.tiers.tiers_hubspotid" target="_blank" class="ml-2">
												<i><font-awesome-icon :icon="['cf', 'hubspot']"/></i>
											</a>
											<e-select
												v-model="quotation.tiers"
												track-by="tiers_id"
												label="tiers_rs"
												:placeholder="this.getTrad('tiers.rechercher')"
												:selectedLabel="getTrad('global.selected_label')"
												:options="tiers"
												:searchable="true"
												:allow-empty="false"
												:show-labels="false"
												:class="{ 'is-invalid': errors && errors.indexOf('tiers') > -1 }"
											>
												<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
												<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
										</div>
									</div>

									<div class="col-6">
										<div class="mb-2">
											<label class="mb-1 mr-2" for="contact_id">{{ $t('horse.contact') }} *</label>
											<router-link v-if="quotation.contact" :to="{ name: 'contactFiche', params: { contact_id: quotation.contact.contact_id }}">
												<i><font-awesome-icon :icon="['fal', 'link']"/></i>
                                            </router-link>
                                            <!-- <a v-if="quotation.contact && quotation.contact.contact_hubspotid" :href="'https://app.hubspot.com/contacts/8547080/company/' + quotation.contact.contact_hubspotid" target="_blank" class="ml-2">
												<i><font-awesome-icon :icon="['cf', 'hubspot']"/></i>
											</a> -->
											<e-select
												v-if="refresh_contact"
												v-model="quotation.contact"
												track-by="contact_id"
												label="contact_label"
					                            :placeholder="$t('monte.selectionnez_contact')"
					                            :selectedLabel="$t('global.selected_label')"
												:options="contacts"
												:searchable="true"
												:allow-empty="false"
												:show-labels="false"
												@input="oui"
											>
												<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_label }}</template>
												<template slot="option" slot-scope="{ option }">{{ option.contact_label }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
										</div>
									</div>

									<div class="col-6">
										<div class="mb-2">
											<label class="mb-1" for="num_dossier">{{ $t('invoice.devis.num_dossier') }}</label>
											<input type="text" name="num_dossier" class="form-control" v-model="quotation.quotation_num_dossier">
										</div>
									</div>

									<div class="col-6">
										<div class="mb-2">
											<label class="mb-1" for="ref_client">{{ $t('invoice.devis.ref_client') }}</label>
											<input type="text" name="ref_client" class="form-control" v-model="quotation.quotation_ref_client">
										</div>
									</div>

									<div class="col-6">
										<div class="mb-2">
											<label class="mb-1" for="expirated_date">{{ $t('invoice.devis.expirated_date') }}</label>
											<e-datepicker v-model="quotation.quotation_expirated" id="expirated_date" ></e-datepicker>
										</div>
									</div>

									<div v-if="quotation.quotation_status == 'SIG' || quotation.quotation_status == 'CON'" class="col-6">
										<div class="mb-2">
											<label class="mb-1" for="signature_date">{{ $t('invoice.devis.signature_date') }}</label>
											<e-datepicker v-model="quotation.quotation_signature" id="signature_date" ></e-datepicker>
										</div>
									</div>

									<div class="col-12">
										<div class="mb-2">
											<label class="mb-1" for="commentaire">{{ $t('invoice.commentaire') }}</label>
											<textarea id="commentaire" class="form-control" rows="3" v-model="quotation.description"></textarea>
										</div>
									</div>

									<div class="col-12 text-center">
										<b-button variant="primary" rounded-pill @click="validForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t('global.enregistrer') }}</b-button>
									</div>
								</div>
					        </div>
					        <div class="box">
					        	<div class="row mt-2">
									<div class="col-auto">
										<h3>{{ $t('invoice.devis.details_devis') }}</h3>
									</div>
									<div class="col-auto ml-auto d-flex">
								        <a href="" class="btn ml-3 btn-primary" @click.prevent="openDetailsModal">
								            <i><font-awesome-icon :icon="['far', 'layer-plus']" /></i> {{ $t('global.ajouter') }}
								        </a>
									</div>
			                    	<div v-if="loadingDetails" class="col-12">
			                    		<LoadingSpinner />
			                    	</div>
									<div v-else class="col-12 my-3">
										<draggable class="list-group" group="colonne" :list="details">
					                        <div class="row list-group-item  d-flex align-items-center" v-for="el in details" :key="el.quotationdetails_id" @dragend="orderDetails">
					                        	<div class="col">
					                        		<div class="row">
														<font-awesome-icon :icon="['far', 'sort']" class="mr-3" />
							                        	{{ el.quotationdetails_label }}
							                        </div>
							                        <div v-if="el.quotationdetails_description" class="row mt-2">
							                        	<span v-html="cleanHTML(el.quotationdetails_description)"></span>
							                        </div>
						                        </div>
					                        	<div class="col-1">
						                       		<div class="ml-auto">
						                       			<b-button variant="secondary" @click="editDetails(el.quotationdetails_id)"><font-awesome-icon :icon="['fal', 'edit']" /></b-button>
						                       			<b-button variant="secondary" class="mr-2" @click="deleteDetails(el.quotationdetails_id)"><font-awesome-icon :icon="['fal', 'trash']" /></b-button>
						                       		</div>
						                       	</div>
					                        </div>
					                    </draggable>
					                </div>
									<div class="col-12 text-center">
										<b-button variant="primary" rounded-pill @click="saveDetailsOrder"><font-awesome-icon v-if="processing_details" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t('global.enregistrer') }}</b-button>
									</div>
								</div>
					        </div>
					    </template>
				    </div>
				</div>
			</div>
		</div>
		<ModalAddDevisDetails 
			v-if="quotation.quotation_id"
			ref="add_details_modal"
			:quotation_id="quotation_id" 
			:author_id="quotation.author.tiers_id"
			:tiers_id="quotation.tiers.tiers_id"
		/>

        <b-modal size="xl" ref="modalPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

        <ModalConfirm
			ref="modalDeleteDetails"
			id="modalDeleteDetails"
			:icon="['fal', 'trash']"
			:icon_accept="['fal', 'level-down']"
			:text_title="$t('invoice.devis.confirm_details_suppression')"
			:text_question="$t('invoice.devis.confirm_details_suppression_quest')"
			:text_button_ok="$t('global.supprimer')"
			:text_button_cancel="$t('modal.general.annuler')"
			:callback_validation="confirmDeleteDetails"
			:callback_cancel="closeModalDeleteDetails"
			:processing="processing_delete_details"
		/>

        <ModalSendQuotation ref="modal_send_quotation" @submit="devisSended"/>

        <b-modal ref="modalShare" hide-footer>
            <template v-slot:modal-title>
                {{ $t("invoice.devis.lien_partage") }}
            </template>

			<b-input-group>
				<b-form-input :disabled="true" v-model="signature_link"></b-form-input>
				<b-input-group-append>
					<b-button @click="copyURL(link)"><font-awesome-icon :icon="['far', 'copy']" /></b-button>
				</b-input-group-append>
			</b-input-group>
        </b-modal>

        <b-modal ref="modalSignature" hide-footer>
            <template v-slot:modal-title>
                {{ $t("invoice.devis.lien_signature") }}
            </template>

			<b-input-group>
				<b-form-input :disabled="true" v-model="signature_link"></b-form-input>
				<b-input-group-append>
					<b-button @click="copyURL(signature_link)"><font-awesome-icon :icon="['far', 'copy']" /></b-button>
				</b-input-group-append>
			</b-input-group>
        </b-modal>
	</main>
</template>

<script type="text/javascript">
    import Quotation from "@/mixins/Quotation.js"
    import Tiers from "@/mixins/Tiers.js"
    import Navigation from "@/mixins/Navigation.js"
    import Contact from "@/mixins/Contact.js"
	import Common from '@/assets/js/common'
	import draggable from "vuedraggable";
	import xss from 'xss'

	export default {
		name: 'EditDevis',
		mixins: [Quotation, Tiers, Navigation, Contact],
		props: ['quotation_id'],
		data () {
			return {
				quotation_status: ['BRO','ENV','SIG','REF','SUP', 'CON', 'FPO'],
				entity: [],
				tiers: [],
				processing: false,
				quotation: {
				},
				errors: [],
				loading: false,
				events_tab: {
					'EditDevis::ReloadDetails': this.loadDetails
				},
				details: [],
				processing_details: false,
				loadingDetails: false,
				base64: null,
				processing_download: false,
				processing_convert: false,
				processing_delete_details: false,
				processing_signature: false,
				detail_to_delete: null,
				link: null,
				signature_link: null,
				processing_share: false,
				contacts: [],
				refresh_contact: true
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.entity = await this.loadTiersEntity()
				this.tiers = await this.loadTiers()

				if(this.quotation_id) {
					const quotation = await this.getQuotationById(this.quotation_id)
					this.quotation = quotation
					this.quotation.author = this.entity.find(tier => tier.tiers_id == quotation.quotation_author)
					this.quotation.tiers = this.tiers.find(tier => tier.tiers_id == quotation.quotation_tiers)
					await this.loadTiersContact()
					this.quotation.contact = this.contacts.find(contact => contact.contact_id == quotation.quotation_contact)
					this.quotation.quotation_created = new Date(this.quotation.quotation_created)
					this.quotation.quotation_signature = this.quotation.quotation_signature ? new Date(this.quotation.quotation_signature) : new Date()
					this.quotation.quotation_expirated = this.quotation.quotation_expirated ? new Date(this.quotation.quotation_expirated) : null
					this.quotation.ttc_format = await Common.priceFormat(this.quotation.quotation_ttc)
					this.quotation.ht_format = await Common.priceFormat(this.quotation.quotation_ht)
					this.quotation.vat_format = await Common.priceFormat(this.quotation.quotation_vat)
					this.quotation.description = this.quotation.quotation_description

					this.loadDetails()
				}
				this.loading = false
			},

			openModal() {
                this.$refs.modal.show()
			},

			async validForm() {
				this.processing = true
				this.errors = []

				if(!this.quotation.author) {
					this.errors.push('author')
				}
				if(!this.quotation.tiers) {
					this.errors.push('tiers')
				}

				if(this.errors.length > 0) {
					this.processing = false
					return false
				}

				const params = {
					author: this.quotation.author.tiers_id,
					tiers: this.quotation.tiers.tiers_id,
					contact: this.quotation.contact ? this.quotation.contact.contact_id : null,
					num_dossier: this.quotation.quotation_num_dossier ? this.quotation.quotation_num_dossier : null,
					ref_client: this.quotation.quotation_ref_client ? this.quotation.quotation_ref_client : null,
					description: this.quotation.description ? this.quotation.description : null,
					created_date: this.quotation.quotation_created,
					signature_date: this.quotation.quotation_status == 'SIG' ? this.quotation.quotation_signature : null,
					expirated_date: this.quotation.quotation_status == 'SIG' ? this.quotation.quotation_expirated : null,
					status: this.quotation.quotation_status
				}

				const quotation = await this.updateQuotation(this.quotation_id, params)
				this.init_component()
				this.processing = false
			},

			openDetailsModal() {
                this.$refs.add_details_modal.openModal()
			},

            async quickPreview() {
                this.$refs.modalPreview.show()
                let retour = await this.getQuotationPdf(this.quotation.quotation_id, this.quotation.quotation_num, true)
                if(retour) {
                    this.base64 = retour
                }
            },

            async downloadPdf() {
            	this.processing_download = true
            	await this.getQuotationPdf(this.quotation.quotation_id, this.quotation.quotation_num)
            	this.processing_download = false
            },

			async loadDetails() {
				this.loadingDetails = true
				this.details = await this.getDetailsByQuotation(this.quotation_id)
				this.loadingDetails = false
			},

			orderDetails() {
				for (var key in this.details) {
					this.details[key].quotationdetails_order = key
				}
				this.details.sort(function(a, b){return a.quotationdetails_order-b.quotationdetails_order})
			},

			async saveDetailsOrder() {
				this.processing_details = true
				const details_order = this.details.map(detail => {
					return {
						quotationdetails_id: detail.quotationdetails_id,
						quotationdetails_order: detail.quotationdetails_order
					}
				})

				await this.saveQuotationDetailsOrder(details_order)
				this.successToast("toast.info_modif_succes")
				this.processing_details = false
			},

			editDetails(details_id) {
                this.$refs.add_details_modal.openModal(details_id)
			},

			deleteDetails(details_id) {
				this.detail_to_delete = details_id
				this.$refs.modalDeleteDetails.openModal()
			},

			async confirmDeleteDetails() {
				this.processing_delete_details = true
				await this.deleteQuotationDetails(this.detail_to_delete)
				this.processing_delete_details = false
				this.detail_to_delete = null
				this.closeModalDeleteDetails()
				this.loadDetails()
			},

			closeModalDeleteDetails() {
				this.$refs.modalDeleteDetails.closeModal()
			},

            async convertQuotation() {
            	this.processing_convert = true
                await this.convertQuotationToBAF(this.quotation.quotation_id)
				this.successToast("toast.info_modif_succes")
                this.processing_convert = false
            },

            openModalSendQuotation() {
				this.$refs.modal_send_quotation.openModal(this.quotation.quotation_id)
			},

            devisSended() {
				this.successToast('invoice.devis.send_success')
				this.init_component()
			},

			openDocument() {
				this.$router.push({ name: 'DevisDocument', params: {quotation_id: this.quotation.quotation_id}})
			},

			async generateLink() {
				this.processing_share = true
				this.link = await this.generateQuotationLink(this.quotation_id)
				this.$refs.modalShare.show()
				this.processing_share = false
			},

			async loadTiersContact() {
				let res = await this.getContactByTiers(this.quotation.tiers.tiers_id)
				this.contacts = res.map(r => r.contact)
			},

			oui() {
				this.refresh_contact = false
				this.$nextTick(() => {
					this.refresh_contact = true
				})
			},

			async generateSignatureLink() {
				this.processing_signature = true
				const res = await this.generateQuotationSignatureLink(this.quotation_id)
				if(res.success) {
					this.signature_link = res.url
					this.$refs.modalSignature.show()
				}
				this.processing_signature = false
			},

			async copyURL(mytext) {
				try {
					await navigator.clipboard.writeText(mytext);
				} catch($e) {
					console.error($e);
				}
			},

			cleanHTML(input) {
				return xss(input)
			}
		},

		computed: {
			dragOptions() {
				return {
					animation: 0,
					group: "label",
					disabled: false,
					ghostClass: "ghost"
				};
			}
		},

		watch: {
			'quotation.tiers'() {
				this.loadTiersContact()
			}
		},

		components: {
			draggable,
            HeaderTab: () => import('@/components/HeaderTab'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ModalAddDevisDetails: () => import('@/components/Invoice/ModalAddDevisDetails'),
            ModalSendQuotation : () => import('@/components/Invoice/ModalSendQuotation'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm')
		}
	}

</script>